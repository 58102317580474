<template>
  <div id="property-add">
    <property-form
      :data="property"
      :company="company"
      :loading="loading"
      @submit="handleSubmit"
    >
      <template #title>
        <p>
          Adding property for <strong>{{ company.human_name }}</strong>
        </p>
      </template>
    </property-form>
  </div>
</template>

<script>
import BasePropertyForm from './_components/BasePropertyForm'

import RooofAPI from '@/services/api/rooof'
import CraigslistAPI from '@/services/api/craigslist'

import { createBlankCraigslistProperty } from '@/utils/rooof'

export default {
  name: 'PropertyAdd',
  components: {
    'property-form': BasePropertyForm
  },
  props: {
    company: {
      type: Object,
      required: true
    },
    loadingCompany: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      property: createBlankCraigslistProperty()
    }
  },
  methods: {
    /**
     * Callback handler for submit event.
     */
    handleSubmit () {
      this.createProperty()
    },
    /**
     * Serialize form data and send property
     * creation request to the API.
     */
    async createProperty () {
      this.loading = true
      try {
        const propertyData = JSON.parse(JSON.stringify(this.property))

        // Add the company
        propertyData.property.groups = [ this.company.name ]

        // Create the Property object (this will also create a matching CraigslistProperty object)
        const property = await RooofAPI.properties.create(propertyData.property)

        // Update the newly-created CraigslistProperty object with form data
        await CraigslistAPI.properties.update(property.id, propertyData)

        // Add agreements if any
        const promises = []
        for (const agreement of this.property.agreements.added) {
          const formData = new FormData()
          formData.append('file', agreement.raw)
          promises.push(RooofAPI.agreements.create(property.id, 'properties', formData))
        }
        await Promise.all(promises)

        // Navigate user to the Property Edit page
        this.$message({
          message: `Property ${property.name} created`,
          type: 'success',
          duration: 3500
        })
        const params = {
          cid: this.company.id,
          id: property.id
        }
        this.$router.push({ name: 'PropertyEdit', params })
      } catch (err) {
        if (err.response) {
          this.$rfAlert.error(this, err, err.response.data)
        }
        throw err
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.not-found {
  margin: 1em;
}
.not-found div {
  padding: 0.5em;
}
</style>
